<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  route="citizen-report"
                  purpose="add"
                  title="Tambah Pengaduan Masyarakat"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/citizen-report/Form.vue";
import moment from 'moment'

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        title: "",
        category_id: "",
        date_report: moment().format('YYYY-MM-DD'),
        citizen_id: "",
        citizen_name: "",
        description: "",
        status: "1",
      },
    };
  },
  mounted() {
    // let user = localStorage.getItem("user");
    // user = JSON.parse(user)

    // console.log(user, "user");
    // if (user) {
    //   this.form.citizen_id = user.id;
    //   this.form.citizen_name = user.name;
    // } else {
    //   this.$router.push(`/citizen-report/list`);
    // }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Pengaduan", route: "/citizen-report/list" },
      { title: "Tambah Pengaduan" },
    ]);
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
